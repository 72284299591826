@font-face {
    font-family: 'fontello';
    src: url('./font/fontello.eot?22360681');
    src: url('./font/fontello.eot?22360681#iefix') format('embedded-opentype'),
        url('./font/fontello.woff2?22360681') format('woff2'),
        url('./font/fontello.woff?22360681') format('woff'),
        url('./font/fontello.ttf?22360681') format('truetype'),
        url('./font/fontello.svg?22360681#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?22360681#fontello') format('svg');
  }
}
*/

[class^='icon-']:before,
[class*=' icon-']:before {
    font-family: 'fontello';
    font-style: normal;
    font-weight: normal;
    speak: never;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: 0.2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

[class^='iconc-']:before,
[class*=' iconc-']:before {
    font-family: 'fontello';
    font-style: normal;
    font-weight: normal;
    speak: never;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    position: absolute;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */

    // border-top: solid 1px #e8e8e8;
    // border-bottom: solid 1px #e8e8e8;
    // border-left: solid 1px #e8e8e8;
    // padding: 11px 12px 15px 1px;
    color: #8b8b8b;
    border-radius: 2px;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
.icon-career-search:before {
    content: '\e800';
} /* '' */
.icon-bookmark:before {
    content: '\e801';
} /* '' */
.icon-certification:before {
    content: '\e802';
} /* '' */
.icon-cross:before {
    content: '\e803';
} /* '' */
.icon-disabillity-symbol:before {
    content: '\e804';
} /* '' */
.icon-chat_icon:before {
    content: '\e805';
} /* '' */
.icon-distance_learning:before {
    content: '\e806';
} /* '' */
.icon-down_arrow:before {
    content: '\e807';
} /* '' */
.icon-edit-1:before {
    content: '\e808';
} /* '' */
.icon-ex--alunmi:before {
    content: '\e809';
} /* '' */
.icon-ex-defence:before {
    content: '\e80a';
} /* '' */
.icon-executive-program:before {
    content: '\e80b';
} /* '' */
.icon-facebook-logo:before {
    content: '\e80c';
} /* '' */
.icon-full-time:before {
    content: '\e80d';
} /* '' */
.icon-gender:before {
    content: '\e80e';
} /* '' */
.icon-information:before {
    content: '\e80f';
} /* '' */
.icon-linkedin-logo:before {
    content: '\e810';
} /* '' */
.icon-part-time:before {
    content: '\e811';
} /* '' */
.icon-refresh2:before {
    content: '\e812';
} /* '' */
.icon-refresh:before {
    content: '\e813';
} /* '' */
.icon-right_arrow:before {
    content: '\e814';
} /* '' */
.icon-shield:before {
    content: '\e815';
} /* '' */
.icon-star:before {
    content: '\e816';
} /* '' */
.icon-star2:before {
    content: '\e817';
} /* '' */
.icon-twitter_logo:before {
    content: '\e818';
} /* '' */
.icon-woman:before {
    content: '\e819';
} /* '' */
.icon-candidate:before {
    content: '\e81a';
} /* '' */
.icon-checked_icon:before {
    content: '\e81b';
} /* '' */
.icon-close_icon:before {
    content: '\e81c';
} /* '' */
.icon-group:before {
    content: '\e81d';
} /* '' */
.icon-review:before {
    content: '\e81e';
} /* '' */
.icon-down_arrow-1:before {
    content: '\e81f';
} /* '' */
.icon-download_arrow:before {
    content: '\e820';
} /* '' */
.icon-email_envelope:before {
    content: '\e821';
} /* '' */
.icon-info:before {
    content: '\e822';
} /* '' */
.icon-mail:before {
    content: '\e823';
} /* '' */
.icon-search_icon:before {
    content: '\e824';
} /* '' */
.icon-notification_bell_outline_interface_symbol:before {
    content: '\e825';
} /* '' */
.icon-politics:before {
    content: '\e826';
} /* '' */
.icon-denied:before {
    content: '\e827';
} /* '' */
.icon-star_shaped_medal:before {
    content: '\e828';
} /* '' */
.icon-sunrise:before {
    content: '\e829';
} /* '' */
.icon-tick:before {
    content: '\e82a';
} /* '' */
.icon-star_later:before {
    content: '\e82b';
} /* '' */
.icon-add_icon:before {
    content: '\e82c';
} /* '' */
.icon-back_icon:before {
    content: '\e82d';
} /* '' */
.icon-badge:before {
    content: '\e82e';
} /* '' */
.icon-box:before {
    content: '\e82f';
} /* '' */
.icon-back_icon-1:before {
    content: '\e830';
} /* '' */
.icon-interview:before {
    content: '\e831';
} /* '' */
.icon-work_from_home:before {
    content: '\e832';
} /* '' */
.icon-user:before {
    content: '\e833';
} /* '' */
.icon-location:before {
    content: '\e834';
} /* '' */
.icon-chat:before {
    content: '\e835';
} /* '' */
.icon-report:before {
    content: '\e836';
} /* '' */
.icon-small-calendar:before {
    content: '\e837';
} /* '' */
.icon-calendar:before {
    content: '\e838';
} /* '' */
.icon-reviewed:before {
    content: '\e839';
} /* '' */
.icon-shortlist:before {
    content: '\e83a';
} /* '' */
.icon-briefcase:before {
    content: '\e83b';
} /* '' */
.icon-clock:before {
    content: '\e83c';
} /* '' */
.icon-tick_circle:before {
    content: '\e83d';
} /* '' */
.icon-telephone:before {
    content: '\e83e';
} /* '' */
.icon-face-to-face:before {
    content: '\e83f';
} /* '' */
.icon-sunset:before {
    content: '\e840';
} /* '' */
.icon-search:before {
    content: '\e841';
} /* '' */
.icon-afternoon:before {
    content: '\e842';
} /* '' */
.icon-filter:before {
    content: '\e843';
} /* '' */
.icon-up_arrow:before {
    content: '\e844';
} /* '' */
.icon-cross_icon:before {
    content: '\e845';
} /* '' */
.icon-more:before {
    content: '\e846';
} /* '' */
.icon-minus_icon:before {
    content: '\e847';
} /* '' */
.icon-send_button:before {
    content: '\e848';
} /* '' */
.icon-information-1:before {
    content: '\e849';
} /* '' */
.icon-user_icon:before {
    content: '\e84a';
} /* '' */
.icon-twitter:before {
    content: '\e84b';
} /* '' */
.icon-facebook:before {
    content: '\e84c';
} /* '' */
.icon-linkedin:before {
    content: '\e84d';
} /* '' */
.icon-light-bulb:before {
    content: '\e84e';
} /* '' */
.icon-heart-empty:before {
    content: '\e84f';
} /* '' */
.icon-star_icon:before {
    content: '\e850';
} /* '' */
.icon-download_icon:before {
    content: '\e851';
} /* '' */
.icon-employeeassistanceprogram:before {
    content: '\e852';
} /* '' */
.icon-review_icon:before {
    content: '\e853';
} /* '' */
.icon-clock-1:before {
    content: '\e854';
} /* '' */
.icon-chat_bubble:before {
    content: '\e855';
} /* '' */
.icon-face_to_face_icon2:before {
    content: '\e856';
} /* '' */
.icon-award:before {
    content: '\e857';
} /* '' */
.icon-baby-boy:before {
    content: '\e858';
} /* '' */
.icon-asterisk:before {
    content: '\e859';
} /* '' */
.icon-flexibleworkinghours:before {
    content: '\e85a';
} /* '' */
.icon-lactation-rooms:before {
    content: '\e85b';
} /* '' */
.icon-maternity-paternity-leave:before {
    content: '\e85c';
} /* '' */
.icon-medical-checkup:before {
    content: '\e85d';
} /* '' */
.icon-mother1:before {
    content: '\e85e';
} /* '' */
.icon-paid-sabbatical:before {
    content: '\e85f';
} /* '' */
.icon-parant-policysvg:before {
    content: '\e860';
} /* '' */
.icon-phone-contact:before {
    content: '\e861';
} /* '' */
.icon-pregnant:before {
    content: '\e862';
} /* '' */
.icon-voluntary-parent-coverage:before {
    content: '\e863';
} /* '' */
.icon-work-from-home:before {
    content: '\e864';
} /* '' */
.icon-face_to_face_icon:before {
    content: '\e865';
} /* '' */
.icon-history-button:before {
    content: '\e866';
} /* '' */
.icon-mother:before {
    content: '\e867';
} /* '' */
.icon-clap-white:before {
    content: '\e868';
} /* '' */
.icon-clap-black:before {
    content: '\e869';
} /* '' */
.icon-left:before {
    content: '\e86a';
} /* '' */
.icon-refresh1:before {
    content: '\e86b';
} /* '' */
.icon-minus:before {
    content: '\e86c';
} /* '' */
.icon-plus-1:before {
    content: '\e86d';
} /* '' */
.icon-roundzero:before {
    content: '\e86e';
} /* '' */
.icon-phone:before {
    content: '\e86f';
} /* '' */
.icon-comment:before {
    content: '\e870';
} /* '' */
.icon-share1:before {
    content: '\e872';
} /* '' */
.icon-attach:before {
    content: '\e873';
} /* '' */
.icon-calendar-5:before {
    content: '\e874';
} /* '' */
.icon-project-icon-db:before {
    content: '\e875';
} /* '' */
.icon-upload:before {
    content: '\e876';
} /* '' */
.icon-generic1:before {
    content: '\e877';
} /* '' */
.icon-trash:before {
    content: '\e878';
} /* '' */
.icon-right:before {
    content: '\e879';
} /* '' */
.icon-video-camera:before {
    content: '\e87a';
} /* '' */
.icon-information-fill:before {
    content: '\e87e';
} /* '' */
.icon-forward:before {
    content: '\e880';
} /* '' */
.icon-back:before {
    content: '\e881';
} /* '' */
.icon-github-circled-alt:before {
    content: '\f053';
} /* '' */
.icon-github-circled:before {
    content: '\f054';
} /* '' */
.icon-share:before {
    content: '\f1e0';
} /* '' */
.icon-question-circle-o:before {
    content: '\f29c';
} /* '' */
.icon-spinner2:before {
    content: '\f529';
} /* '' */

a {
    text-decoration: none;
}

.left {
    float: left;
}

.right {
    float: right;
}

.clear {
    clear: both;
    overflow: auto;
}

.clearfix {
    display: table;
    clear: both;
}

.info-text {
    font-size: 14px;
    @include themify() {
        color: themed(default-text-color);
    }
    line-height: 20px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
$logoFontName: 'Comfortaa';
$primary-font: 'Droid Sans', sans-serif;
$logoFontSize: 30px;
$logoBylineFontSize: 14px;
$logoCaptionFontSize: 11px;
$logoTaglineFontSize: 11px;

$logo-font-size-mobile: 21px;
$logo-byline-font-size-mobile: 11px;
$logo-tagline-font-size-mobile: 9px;

$label-font-size: 14px;
$select-font-size: 14px;
$base-font-size: 14px;

$error-message-font-size: 11px;

$options-list-font-size: 13px;

$page-title-size: 22px;

$page-section-title-size: 20px;
$page-section-font-size: 13px;

$page-subtitle-font-size: 18px;

$base-subtitle-size: 16px;

$candidate-skill-font-size: 12px;

$mobile-page-title-size: 16px;
$mobile-page-subtitle-size: 12px;

$mobile-page-section-title-size: 20px;

$mobile-base-font-size: 12px;

$select-minimal-font-size: 16px;

$page-table-header-font-size: 15px;

$view-resume-section-title-size: 16px;
$view-resume-byline-font-size: 13px;
$view-resume-clear-tag-font-size: 11px;
$view-resume-listing-font-size: 15px;

$resume-listing-font-size: 15px;

$chat-sidebar-user-name-font-size: 13px;
$chat-sidebar-user-designation-font-size: 12px;
$chat-timestamp-font-size: 12px;

$filter-tag-size: 12px;

$modal-title-font-size: 24px;

$modal_heading-font-size: 18px;
$modal-content-font-size: 14px;

/*[Recruiter_Homepage]*/

$login-title-font-size: 24px;
$landing-title-font-size: 24px;
$login-input-font-size: 16px;
$landing-subtitle-font-size: 16px;
/*[/Recruiter_Homepage]*/

$lead-gen-heading-font-size: 28px;
$lead-gen-sub-heading-font-size: 24px;

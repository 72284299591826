/**
 * APP Styles
 * Add Global styles here
 */
@import '../styles/fontello.scss';

body,
html {
    margin: 0;
    padding: 0;
    min-height: 100%;
    height: 100%;
}

* {
    font-weight: normal;
    font-family: 'Droid Sans', sans-serif;
    line-height: normal;
}

*,
*:before,
*:after {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
}

p {
    margin: 0;
}

.app {
    height: 100%;
}

.font-700 {
    font-weight: 700;
}

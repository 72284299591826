@import '../../../styles/theme.scss';

.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 7;
    @include themify() {
        background-color: themed(shadow-color-dark);
    }
}
